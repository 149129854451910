import React from "react"
import { StaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"

import Layout from "../components/layout"
import ServicesBlock from "../components/services-block"
import ServiceOverview from "../components/service-overview"
import ServiceGrid from "../components/service-grid"
import ContactBlock from "../components/contact-block"

const HeaderImage = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.medium}) {
    flex-direction: column;
  }
`
const ServiceDescription = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.medium}) {
    justify-content: flex-start;
    align-items: flex-start;
  }
  h2 {
    font-weight: 800;
    color: ${({ theme }) => theme.palette.navCol};
    text-decoration: underline;
    text-underline-position: under;
    font-size: 2.2rem;
    margin-bottom: 12px;
    white-space: nowrap;
    @media only screen and (max-width: ${props =>
        props.theme.breakpoints.medium}) {
      margin-top: 40px;
      white-space: initial;
    }
  }

  p {
    padding: 20px;
    @media only screen and (max-width: ${props =>
        props.theme.breakpoints.medium}) {
      padding: 20px 0px;
    }
  }
`

const Image = styled(Img)`
  height: 500px;
  min-width: 350px;
  flex: 1;
  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.medium}) {
    order: 1;
    flex: initial;
    min-width: initial;
    height: 300px;
  }
`

const Margins = styled.div`
  margin: 0 auto;
  max-width: 1060px;
  margin-top: 120px;

  p {
    font-size: 0.9rem;
    font-weight: 300;
  }
`
const Container = styled.div`
  max-width: 1800px;
  padding: 30px;
  margin: 0 auto;
`

const Border = styled.div`
  height: 5px;
  background-color: ${({ theme }) => theme.palette.navCol};
  width: 300px;
  margin: 0 auto;
  margin-bottom: 40px;
`

const Padding = styled.div`
  padding: 64px;
`

const TextContent = styled.div`
  width: 100%;
  text-align: center;
  padding: 20px;

  h2 {
    text-transform: uppercase;
    letter-spacing: 5px;
    font-size: 35px;
    margin: 20px;
    font-weight: 800;
  }

  p {
    line-height: 30px;
    font-size: 18px;
  }
`

const Services = () => (
  <StaticQuery
    query={graphql`
      {
        photo3: file(name: { eq: "AdobeStock_115067132" }) {
          name
          childImageSharp {
            fluid(maxWidth: 500, maxHeight: 500, cropFocus: CENTER, quality: 100) {
              src
              aspectRatio
            }
          }
        }
        photo2: file(name: { eq: "AdobeStock_96507125" }) {
          name
          childImageSharp {
            fluid(maxWidth: 500, maxHeight: 500, cropFocus: CENTER, quality: 100) {
              src
              aspectRatio
            }
          }
        }
        photo1: file(name: { eq: "Service" }) {
          name
          childImageSharp {
            fluid(maxWidth: 500, maxHeight: 500, cropFocus: CENTER, quality: 100) {
              src
              aspectRatio
            }
          }
        }
      }
    `}
    render={props => (
      <Layout>
        {/* <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} /> */}
        <Margins>
          <TextContent>
            <h2>Service</h2>
            <p>
              Erwarten Sie von uns keine 08/15-Lösung, sondern eine perfekt zu
              Ihren individuellen Vorstellungen passende Tor- oder Türlösung,
              welche auch die baulichen Bedingungen und Spezifikationen vor Ort
              berücksichtigt.
            </p>
          </TextContent>
        </Margins>

        <Container>
          <Border />
          <HeaderImage>
            <ServiceDescription>
              <h2>Beratung und Planung</h2>
              <p>
                Wir beraten Sie von der Planung bis hin zur Umsetzung und
                gestalten gemeinsam mit Ihnen eine perfekte Lösung für Ihre
                Garage.
              </p>
            </ServiceDescription>
            <Image
              fluid={props.photo3.childImageSharp.fluid}
              alt="Header image"
            />
          </HeaderImage>

          <HeaderImage>
            <Image
              fluid={props.photo1.childImageSharp.fluid}
              alt="Header image"
            />
            <ServiceDescription>
              <h2>Montage und Sanierung</h2>
              <p>
                Egal ob Montage eines neuen Garagentors oder Sanierung /
                Modernisierung des bestehenden Tors. Wir sind für Sie da.
              </p>
            </ServiceDescription>
          </HeaderImage>
          <HeaderImage>
            <ServiceDescription>
              <h2>Service und Wartung</h2>
              <p>
                Bei uns zählt das Prinzip “Alles aus einer Hand” deshalb sehen
                wir uns auch bei der Wartung nach der Montage Ihres Garagentors
                in der Verantwortung.
              </p>
            </ServiceDescription>
            <Image
              fluid={props.photo2.childImageSharp.fluid}
              alt="Header image"
            />
          </HeaderImage>
        </Container>
        {/* 
        <ServiceOverview />

        <Margins>
          <ServiceGrid color="darkText" bgCol="spanCol" />

          <Padding>
            <ContactBlock />
          </Padding>
        </Margins> */}
      </Layout>
    )}
  />
)

export default Services
