import React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  background: ${({ theme }) => theme.palette.spanCol};
  position: relative;
  padding: 64px;
  margin: 48px 0;

  h2 {
    margin-bottom: 32px;
    width: min-content;
    font-size: 2.2rem;
    font-weight: 800;
    text-decoration: underline;
  }

  h3 {
    font-weight: 800;
    margin-bottom: 12px;
  }
`

const Margins = styled.div`
  margin: 0 auto;
  max-width: 1060px;

  p {
    font-size: 0.9rem;
    font-weight: 300;
  }
`

const FlexGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media only screen and (max-width: ${props => props.theme.breakpoints.small}) {
    flex-direction: column;
  }
`

const GridItem = styled.div`
  display: flex;
  flex: 0 0 43%;
  margin: 32px 0;

  div > p {
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1.3rem;
  }
`

const Number = styled.h3`
  padding: 0 24px 0 0;
`

const ServiceOverview = () => (
  <Wrapper>
    <Margins>
      <h2>SERVICES OVERVIEW</h2>
      <FlexGrid>
        <GridItem>
          <Number>1</Number>
          <div>
            <h3>What we do</h3>
            <p>
              In hendrerit efficitur condimentum dapibus. Integer efficitur leo
              ac odio ornare, eget pulvinar tellus rhoncus. Mauris mollis
              posuere pharetra. Suspendisse facilisis vitae felis non sagittis.
            </p>
          </div>
        </GridItem>
        <GridItem>
          <Number>2</Number>
          <div>
            <h3>Maintenance</h3>
            <p>
              In hendrerit efficitur condimentum dapibus. Integer efficitur leo
              ac odio ornare, eget pulvinar tellus rhoncus. Mauris mollis
              posuere pharetra. Suspendisse facilisis vitae felis non sagittis.
            </p>
          </div>
        </GridItem>
        <GridItem>
          <Number>3</Number>
          <div>
            <h3>Benefits</h3>
            <p>
              In hendrerit efficitur condimentum dapibus. Integer efficitur leo
              ac odio ornare, eget pulvinar tellus rhoncus. Mauris mollis
              posuere pharetra. Suspendisse facilisis vitae felis non sagittis.
            </p>
          </div>
        </GridItem>
        <GridItem>
          <Number>4</Number>
          <div>
            <h3>Customer Satisfaction Guarantee</h3>
            <p>
              In hendrerit efficitur condimentum dapibus. Integer efficitur leo
              ac odio ornare, eget pulvinar tellus rhoncus. Mauris mollis
              posuere pharetra. Suspendisse facilisis vitae felis non sagittis.
            </p>
          </div>
        </GridItem>
      </FlexGrid>
    </Margins>
  </Wrapper>
)

export default ServiceOverview
