import React from "react"
import styled from "styled-components"

import ContactForm from "./contact-form"

import phoneIcon from "../images/round-local_phone-24px.svg"
import mailIcon from "../images/round-email-24px.svg"
import placeIcon from "../images/round-place-24px.svg"

const BlockText = styled.div`
  max-width: 350px;

  h1 {
    font-size: 2.6rem;
    font-weight: 800;
    text-decoration: underline;
    // text-underline-position: under;
    margin-bottom: 16px;
    text-transform: uppercase;
  }

  p {
    line-height: 1.5rem;
  }
`

const Flex = styled.div`
  display: flex;
  align-items: center;

  h3 {
    text-transform: uppercase;
    font-weight: 700;
  }

  p {
    font-size: 0.8rem;
    line-height: 1.4rem;
  }
`

const FlexGrid = styled.div`
  padding: 50px;
`

const Icon = styled.div`
  display: block;
  opacity: 0.45;

  img {
    margin: 32px;
    margin-left: 0;
    width: 40px;
  }
`

const ContactBlockWrapper = styled.div`
  padding-top: 100px;
  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.large}) {
    margin-left: 20px;
  }
`

const Margins = styled.div`
  max-width: 1060px;
  margin: 0 auto;
`

const FormWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.small}) {
      flex-wrap: wrap;
      flex-direction: column;

      > * {
        width: 100%;
      }
  }
`

const ContactBlock = props => (
  <Margins>
    <ContactBlockWrapper id={props.id}>

    <BlockText>
      <h1>Kontakt</h1>
      <p>
        Bitte senden Sie uns Ihre Fragen über das Kontaktformular. Wir werden
        uns umgehend mit Ihnen in Verbindung setzen.
        <br />
        <br />
        Ihr Team von Schneider Tore.
      </p>
    </BlockText>

    <FormWrapper>
      <ContactForm />
      <FlexGrid>
        <Flex>
          <Icon>
            <img src={placeIcon} alt="Icon" />
          </Icon>
          <p>
            <h3>ADDRESSE</h3>
            <p>
              Michael Schneider
              <br />
              Amselweg 13
              <br />
              86559 Adelzhausen
            </p>
          </p>
        </Flex>

        <Flex>
          <Icon>
            <img src={mailIcon} alt="Icon" />
          </Icon>
          <p>
            <h3>E-MAIL</h3>
            <a href="mailto:mail@schneider-tore.de?subject=Nachricht%20Kontaktformular">
              mail@schneider-tore.de
            </a>
          </p>
        </Flex>

        <Flex>
          <Icon>
            <img src={phoneIcon} alt="Icon" />
          </Icon>
          <p>
            <h3>TELEFON</h3>
            <p>
              TEL: (+49) 08258/783
              <br />
              FAX: (+49) 08258/772
            </p>
          </p>
        </Flex>
      </FlexGrid>
      </FormWrapper>
    </ContactBlockWrapper>
  </Margins>
)

export default ContactBlock
