import React from "react"
import styled from "styled-components"

const Flex = styled.div`
  display: flex;
  flex-direction: ${props => props.direction || "row"};
  flex: ${props => props.flex};
  justify-content: space-evenly;

  @media only screen and (max-width: ${props => props.theme.breakpoints.small}) {
    flex-direction: column;
    align-items: center;
    margin: 50px auto;
  }
`

const SpanText = styled.div`
  justify-content: center;
  // padding: 64px;
  margin: 64px;
  text-align: left;
  align-items: flex-start;
  flex: ${props => (props.side === "left" ? 1 : 2)};

  h2 {
    font-weight: 800;
    text-decoration: underline;
    text-underline-position: under;
    font-size: 2.2rem;
    margin-bottom: 12px;
    white-space: nowrap;
  }

  ul {
    padding-top: 12px;
    padding-left: 18px;

    li {
      font-weight: 300;
      white-space: nowrap;

      @media only screen and (max-width: 400px) {
        white-space: normal;
      }
    }
  }

  @media only screen and (max-width: ${props => props.theme.breakpoints.small}) {
    max-width: 80%;
    margin: 18px 64px;
  }
`

const LeftText = styled.p`
  text-align: justify;
  font-weight: 500 !important;
`

const ServicesBlock = () => (
  <Flex>
    <SpanText direction="column" side="left">
      <h2>OUR SERVICES</h2>
      <LeftText>
        In hendrerit efficitur condimentum dapibus. Integer efficitur leo ac
        odio ornare, eget pulvinar tellus rhoncus. Mauris mollis posuere
        pharetra. Suspendisse facilisis vitae felis non.
      </LeftText>
    </SpanText>

    <SpanText side="right">
      <p>
        In hendrerit sagittis ligula a iaculis. Suspendisse ut massa eget erat
        vulputate tempus. Vivamus feugiat at purus quis consequat.
        <ul>
          <li>Praesent efficitur condimentum dapibus.</li>
          <li>Integer efficitur leo ac odio ornare.</li>
          <li>Eget pulvinar tellus rhoncus.</li>
          <li>Mauris mollis posuere pharetra.</li>
          <li>Suspendisse facilisis vitae felis non sagittis.</li>
        </ul>
      </p>
    </SpanText>
  </Flex>
)

export default ServicesBlock
