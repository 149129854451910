import React, { useState } from "react"
import styled from "styled-components"
import { Formik, Form, Field, ErrorMessage } from "formik"
// import Grid from "@material-ui/core/Grid"

const Grid = styled.div`
  width: 100%;
`

const Container = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 50px 50px 50px 0;
`

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
`

const Input = styled(Field)`
  height: 40px;
  background-color: ${({ theme }) => theme.palette.lightGrey};
  border-color: ${({ theme }) => theme.palette.lightGrey};
  border-radius: 0px;
  border: none;
  margin: 5px;
  width: 100%;
  padding: 10px;

  &:focus {
    outline: none;
  }
`

const TextArea = styled(Field)`
  background-color: ${({ theme }) => theme.palette.lightGrey};
  border: none;
  width: 100%;
  margin: 5px;
  padding: 10px;
  &:focus {
    outline: none;
  }
`

const Button = styled.button`
  text-transform: uppercase;
  background-color: gray;
  padding: 10px;
  height: 40px;
  width: 100%;
  margin: 5px;
  font-weight: semi-bold;
  color: ${({ theme }) => theme.palette.lightText};
  font-size: 16px;
  letter-spacing: 1px;
  border: none;
  &:focus {
    outline: none;
  }
`
const Checkbox = styled.input`
  margin-right: 10px;
`

const Privacy = styled.div`
  display: flex;
  padding: 10px;
`

const Success = styled.div`
  background: green;
  color: #fff;
  padding: 10px;
  height: 40px;
  width: 100%;
  margin: 5px;
  text-align: center;
`

function ContactForm() {
  const [wasSuccessful, setWasSuccessful] = useState(null)

  return (
    <Container>
      <Grid container style={{ display: "flex", justifyContent: "center" }}>
        <Grid>
        <Formik
        initialValues={{
          name: "",
          email: "",
          nachricht: "",
        }}
        validate={values => {
          const errors = {}
          if (!values.email) {
            errors.email = "Required"
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = "Invalid email address"
          }
          return errors
        }}

        onSubmit={async (values, { setSubmitting }) => {
          // console.log(values)
          // return;
          const response = await fetch(
            `https://europe-west1-sb-mailer.cloudfunctions.net/submitForm/jzicNaJMqfLRRKOZnN2m`,
            {
              method: `post`,
              headers: {
                Accept: `application/json`,
                "Content-Type": `application/json`,
              },
              body: JSON.stringify({...values, recipient: 'mail@schneider-tore.de'}),
            }
          )
          const result = await response.json()
          if (result.msg === `SUCCESS`) {
            setWasSuccessful(true)
          }
          setSubmitting(false)
        }}
      >
        {({ isSubmitting }) => (
          <StyledForm>
            <Input type="text" name="name" placeholder="Name" required/>
            <ErrorMessage name="name" component="div" />
            <Input type="text" name="subject" placeholder="Betreff" required />
            <ErrorMessage name="subject" component="div" />
            <Input type="email" name="email" placeholder="E-mail" required/>
            <ErrorMessage name="email" component="div" />
            <TextArea component='textarea' name="message" rows="5" placeholder="Ihre Nachricht" required />
            <ErrorMessage name="message" component="div" />
            <Privacy><Checkbox type="checkbox" required />
              <p>Ich stimme zu, dass meine Angaben aus dem Kontaktformular zur Beantwortung meiner Anfrage erhoben und verarbeitet werden. Die Daten werden nach abgeschlossener Bearbeitung Ihrer Anfrage gelöscht.
            Sie können Ihre Einwilligung jederzeit widerrufen. Detaillierte Informationen zum Umgang mit Nutzerdaten finden Sie in unseren <a href="/datenschutz">Datenschutzbedingungen</a>.</p>
            </Privacy>
            {wasSuccessful ? <Success>Ihre Nachricht wurde gesendet.</Success> : <Button>Senden</Button> }
          </StyledForm>
        )}
        </Formik>
        </Grid>
      </Grid>
    </Container>
  )
}

export default ContactForm
