import React from "react"
import { StaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image/withIEPolyfill"

const FlexGrid = styled.div`
  background: ${props =>
    props.theme.palette[props.bgCol] || props.theme.palette.navCol};
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 48px;
`

const GridItem = styled.div`
  flex: 0 0 33.33%;
  min-height: 350px;

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.medium}) {
    flex: 0 0 50%;
  }

  @media only screen and (max-width: 570px) {
    flex: 0 0 100%;
  }
`

const GridText = styled.div`
  padding: 48px 32px;
  width: min-content;
  margin: auto;
  font-size: 0.8rem;
  line-height: 1.2rem;
  color: ${props =>
    props.theme.palette[props.color] || props.theme.palette.lightText};

  h2 {
    margin-bottom: 14px;
    font-size: 2.3rem;
    font-weight: 800;
    line-height: normal;
  }

  p {
    font-weight: 500;
  }
`

const GridImage = styled.div`
  position: relative;
  height: 100%;
  width: 100%;

  div {
    height: 100%;
  }

  ::after {
    // content: ${props => props.description};
    content: "2-inch steel sectional door";
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 20px;
    font-size: 0.8rem;
    font-weight: 500;
    color: white;
  }
`

const HR = styled.hr`
  width: 50%;
  border: 1px solid ${props => props.color || "white"};
`

const ServiceGrid = options => (
  <StaticQuery
    query={graphql`
      {
        allImageSharp(
          filter: {
            fluid: {
              originalName: {
                regex: "/SWT_Weiß.png|Smart_Style_Anthrazit_02|g60 sarleinsbach trend szene1b_ohne nummernschild|eurotrend_weiß reihenhaus1|OSD_Scene_Eurostyle D338_7004_DSC8898/"
              }
            }
          }
        ) {
          edges {
            node {
              fluid(maxWidth: 1500, maxHeight: 600, cropFocus: CENTER) {
                originalName
                src
                aspectRatio
              }
            }
          }
        }
      }
    `}
    render={props => (
      <FlexGrid bgCol={options.bgCol} id={options.id}>
        <GridItem>
          <GridText color={options.color}>
            <HR color={options.color} />
            <h2>OUR SERVICES</h2>
            <p>
              Sed velit nulla, finibus in efficitur at, elementum id justo.
              Proin consectetur, nibh at blandit maximus, mauris urna maximus
              ipsum, eu maximus nunc augue at elit. Morbi condimentum enim quis
              erat molestie, nec sollicitudin erat laoreet.
            </p>
          </GridText>
        </GridItem>
        <GridItem>
          <GridImage description="2-inch steel sectional door">
            <Img
              objectFit="cover"
              objectPosition="50% 50%"
              fluid={props.allImageSharp.edges[0].node.fluid}
              aspectRatio={props.allImageSharp.edges[0].node.aspectRatio}
            />
          </GridImage>
        </GridItem>
        <GridItem>
          <GridImage>
            <Img
              objectFit="cover"
              objectPosition="50% 50%"
              fluid={props.allImageSharp.edges[1].node.fluid}
              aspectRatio={props.allImageSharp.edges[1].node.aspectRatio}
            />
          </GridImage>
        </GridItem>
        <GridItem>
          <GridImage>
            <Img
              objectFit="cover"
              objectPosition="50% 50%"
              fluid={props.allImageSharp.edges[4].node.fluid}
              aspectRatio={props.allImageSharp.edges[4].node.aspectRatio}
            />
          </GridImage>
        </GridItem>

        <GridItem>
          <GridImage>
            <Img
              objectFit="cover"
              objectPosition="50% 50%"
              fluid={props.allImageSharp.edges[2].node.fluid}
              aspectRatio={props.allImageSharp.edges[2].node.aspectRatio}
            />
          </GridImage>
        </GridItem>
      </FlexGrid>
    )}
  />
)

export default ServiceGrid
